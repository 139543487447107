// Create user form
.hide-row {
  display: none;
}

.show-row {
  display: flex;
}
.hide-enterprise {
  display: none;
}

.show-enterprise {
  display: flex;
}

// pagination
.pagination {
  .page-item {
    cursor: pointer;
  }
}

// tables
.threedium-admin-table {
  text-align: center;

  thead {
    tr {
      th {
        cursor: pointer;
      }
    }
  }
}

.text-right {
  text-align: right;
}

.filter-data {
  .btn-group {
    position: relative;
    display: flex;
    vertical-align: middle;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-content: stretch;
  }
}

// Loader
#backdrop-root{
  position: relative;
}
.overlay {
  position: absolute;
  top: 0; bottom:0;
  left: 0; right:0;
  width: 100vw;
  height: 100vh;
  background-color: hsla(0, 0%, 0%, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

// Colaborators
.collab-avatar {
  max-width: 50px;
  height: auto;
}

// Icons
.direction-arrows-wrapper {
  display: flex;
  justify-content: center;
}
.direction-arrows-label {
  margin-right: 5px;
}

// Tuf Handler
.tuf-upload-wrapper {
  display: flex;
  padding: 0.25rem 0.5rem;
  align-items: center;
  border: 1px solid #4f5d73;
  border-radius: 0.2rem;
  font-size: 0.875rem;
}
.tuf-upload-input {
  width: 200px;
  margin-right: 10px;
}
.tuf-uploader-label {
  margin-right: 10px;
}

.dist-restore-wrapper {
  /*margin-top: 25px;*/
  margin-bottom: 25px;
}

.section-title {
  display: block;
  margin-top: 15px;
  padding-bottom: 5px;
}

.restore-section {
  padding-top: 10px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 10px;
  border: 1px solid #4f5d73;
  border-radius: 0.2rem;
}
